/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/29c2df938142dc05-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9d77bf98ac82cae7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c0c0430477ad8e26-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b4752728ce28c918-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a2b02d10248e6761-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/71c12f8f48e9a974-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/552baf00d41f0467-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/3d1c671cdf58124d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/b500054574360ee7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/26d3d5ee7a09255c-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/8897b63d4ba74b53-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/11aa2f76145575b3-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/de4f5008bbe3d448-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/9a27f7098c0f5221-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6a4754aad2193478-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/bebbd91b68ec7e5b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a83b002532c529b9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/53c2c04a3cb66592-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/438d66df271e8a00-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ea8a75ce380d7c76-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/66805252d3fded24-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/90ce457ee8cec043-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fe8a99a918c9dff4-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/bb83722ca01b414e-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/af9d511c7a25f62f-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6b30462463a75ce7-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/84d4affa47fcabed-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0ac14a3c407fb3c4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c762c418d6be0c3e-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ce3f06ff8220f479-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/20fca0a84b06d374-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/2dbc97c4c2289ed4-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/57c2f9c15684dfcb-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/2c78b631c1580249-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/fc6fba7ce0876fef-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c9ac84301026d52c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/2a4d1d8ebdb63f65-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/30d2173387678482-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e81100bd7094716d-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6cec856d45e4d440-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a8acc0e4b6e7a16f-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_Pro_94b17f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/3cc61a2a1d48cb85-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Sans_Pro_Fallback_94b17f';src: local("Arial");ascent-override: 104.41%;descent-override: 28.97%;line-gap-override: 0.00%;size-adjust: 94.24%
}.__className_94b17f {font-family: '__Source_Sans_Pro_94b17f', '__Source_Sans_Pro_Fallback_94b17f'
}

